// @file Our library that track events to our data warehouse (on Rudderstack) in order to process (through Snowflake)
import { trackEvent as trackRudderEvent } from '@@/bits/analytics'
import * as Pepin from '@@/bits/pepin_types'

function getRelativeTimestamp(): number {
  if (typeof window !== 'undefined' && window.performance) {
    return Math.round(window.performance.now() / 1000)
  }
  return -1
}

function trackEvent(props: Pepin.EventProperties = {}): void {
  trackRudderEvent('Click', 'Click', null, null, props)
}

const WHITELISTED_CLICK_SELECTOR = 'button,a,[role=button],[data-pepin-trigger=click]'
function extractExtraDataProps(dataset: DOMStringMap): Record<string, any> {
  const extraData = {}
  Object.keys(dataset).forEach((dataKey: string): void => {
    if (dataKey.startsWith('pepin') && dataKey !== 'pepinId' && dataKey !== 'pepinTrigger') {
      const strippedDataKey = dataKey.slice(5)
      const camelizedDataKey = strippedDataKey.substr(0, 1).toLowerCase() + strippedDataKey.substr(1)
      extraData[camelizedDataKey] = dataset[dataKey]
    }
  })
  return extraData
}

function recordWhitelistedClick(e: MouseEvent): void {
  const eventTarget = e.target as HTMLElement
  const closestElementInSelectorList = eventTarget.closest(WHITELISTED_CLICK_SELECTOR) as HTMLElement
  if (closestElementInSelectorList) {
    const target = closestElementInSelectorList.dataset.pepinId || closestElementInSelectorList.id
    const extraData = extractExtraDataProps(closestElementInSelectorList.dataset)
    if (target) {
      const coordinates = { x: e.pageX, y: e.pageY }
      const elapsedTime = getRelativeTimestamp()
      trackEvent({
        target,
        coordinates,
        elapsedTime,
        ...extraData,
      })
    }
  }
}

function captureOnlyWhitelistedClicks(): void {
  document.addEventListener('click', recordWhitelistedClick)
}

export { captureOnlyWhitelistedClicks, recordWhitelistedClick }
